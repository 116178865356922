* {
    font-family: 'Libre Franklin';
  }

.about-hero {
    background: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), #800020a1),
      url("../../../public/assets/images/despre.jpg");
    background-position: center;
    background-size: cover;
    min-height: 600px;
    height: fit-content;
    color: #ffffff;
    padding: 9rem 3rem;
    margin-top: -90px;
}
.about-section {
padding: 0 25rem;
font-weight: 300;
letter-spacing: 1px;
font-size: 18px;
color: #555555;
}
  
.about-content {
font-weight: 300;
letter-spacing: 1px;
font-size: 18px;
color: #555555;
text-align: justify;
}

.about-content p {
font-size: 18px;
line-height: 1.6;
}

header h1.page-title{
    padding: 2rem 0;
}

.about-content p{
    padding: 1.23rem 0;
}