* {
    font-family: 'Libre Franklin';
  }
  
  .contact-hero {
    background: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), #800020a1),
      url("../../../public/assets/images/contact.jpg");
    background-position: center;
    background-size: cover;
    min-height: 600px;
    height: fit-content;
    color: #ffffff;
    padding: 9rem 3rem;
    margin-top: -90px;
  }

  .wrapper {
    background-color: #ffffff;
    padding: 50px 5%; /* Add padding to left and right */
    text-align: left; /* Align text to the left */
  }
  
  .page-header {
    text-align: center; /* Center align the header */
    margin-bottom: 20px;
  }
  
  .page-title {
    font-size: 40px;
    font-weight: bold;
    margin: 0;
    padding-bottom: 10px;
    border-bottom: 2px solid #555555; /* Add a bottom border */
  }
  
  .content {
    margin-top: 20px;
    text-align: center; /* Center align the contact information */
  }
  
  .contact-detail a, .contact-detail b{
    font-size: 24px; /* Adjust font size */
    font-weight: 400; /* Adjust font weight */
    color: #555555; /* Adjust text color */
    margin: 10px 0; /* Add margin for spacing */
  }

  .contact-detail a:hover{
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .hero {
      height: 200px; /* Adjust height for smaller screens */
    }
  
    .heroInner {
      width: 90%;
      height: 70%;
    }
  
    .page-title {
      font-size: 32px;
    }
  
    .contact-detail {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  }
  