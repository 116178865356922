/* Stilizare pentru secțiunea application */
*{
    font-family: 'Libre Franklin';
}

.application-hero {
  background: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), #800020a1),
    url("../../../public/assets/images/aplicare.JPG");
  background-position: center;
  background-size: cover;
  min-height: 600px;
  height: fit-content;
  color: #ffffff;
  padding: 9rem 3rem;
  margin-top: -90px;
}

.application-section {
    background-color: #ffffff;
    padding: 50px 5%; /* Adaugare padding la stânga și la dreapta */
    text-align: left; /* Alinierea la stânga */
    display: flex; /* Utilizează flexbox pentru a alinia elementele pe direcția principală */
    justify-content: space-between; /* Distribuie spațiul în jurul elementelor */
  }
  
  .application-content {
    display: flex;
    width: 100%; /* Ajustează lățimea pentru a se potrivi cu restul ecranului */
  }
  
  .application-list {
    margin-top: 5%;
    width: 30%; /* Ocupă 33% din lățimea paginii */
    padding-right: 20px;
  }
  
  .application-item {
    font-weight: 470;
    letter-spacing: 3px;
    font-size: 20px;
    border-bottom: 1px solid #555555; /* Linie orizontală între întrebări */
    margin-bottom: 10px;
    padding-bottom: 10px; /* Spațiu între întrebare și linie */
  }
  
  .application-answer {
    padding-left: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 18px;
    color: #555555;
    width: 67%; /* Ajustează lățimea răspunsului pentru a nu ocupa întreaga secțiune dreapta */
  }
  
  /* Stil special pentru ultimul element .application-item */
  .application-item:last-child {
    border-bottom: none; /* Elimină linia pentru ultimul element */
  }
  
  @media (max-width: 768px) {

    .application-list{
      flex-direction: column;
      width: 100%;
      text-align: center;
    }
    .application-content {
      flex-direction: column; /* Pentru ecrane mici, afișează întrebările și răspunsurile în coloană */
      width: 100%;
    }
  
    .application-answer {
      width: 100%; /* Pe ecrane mici, răspunsurile ocupă întreaga lățime */
    }
  }

  h1.page-title{
    font-size: 40px;
  }

  div.application-answer h4{
    font-size: 23px;
  }