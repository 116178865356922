.contact-form {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
