.course-list-container {
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.course-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: auto;
  height: 100%; /* Ensure all cards have the same height */
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  margin: auto;
}

.course-card:hover {
  transform: scale(1.05);
  animation: wave 1s infinite;
}

.course-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the link takes the full height of the card */
}

.course-info {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.course-info p{
  text-align: start;
}

.course-title {
  font-weight: bold;
}

.course-location,
.course-period {
  margin-top: 8px;
  color: #555;
}

.course-period {
  margin-top: 4px;
}

/* Wave effect for buttons */
.hero-button,
.apply-button {
  position: relative;
  overflow: hidden;
  transition: color 0.4s;
}

.hero-button::before,
.apply-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 0.75s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 50%;
  z-index: 1;
  opacity: 0;
}

.hero-button:hover::before,
.apply-button:hover::before {
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  opacity: 1;
}

.hero-button:hover,
.apply-button:hover {
  color: white;
  transform: scale(1.05);
  animation: wave 1s infinite;
}

@keyframes wave {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
